/* SECTION ANIMATIONS */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* MEDIA QUERIES */
@media (min-width: 1000px) {
	.title {
		font-size: 2em;
	}
	.info {
		font-size: 1em;
	}
	.input {
		width: 17.5em;
	}
}

@media (min-width: 700px) {
	.title, .info {
		width: 50%;
	}
}