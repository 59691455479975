@import url(https://fonts.googleapis.com/css?family=Finger+Paint|Love+Ya+Like+A+Sister|Blinker|Montserrat|Special+Elite&display=swap);
/* SECTION ANIMATIONS */

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* MEDIA QUERIES */
@media (min-width: 1000px) {
	.title {
		font-size: 2em;
	}
	.info {
		font-size: 1em;
	}
	.input {
		width: 17.5em;
	}
}

@media (min-width: 700px) {
	.title, .info {
		width: 50%;
	}
}
* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
}

body {
  color: whitesmoke;
}

h1{
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

iframe {
  margin-bottom: 150px;
}

a {
  color: whitesmoke;
}

div {
  margin: 0;
  padding: 0;
}

nav {
  /* border: solid turquoise 2px; */
  display: flex;
  justify-content: center;
}

nav ul {
  padding: 0;
  padding-top: 1em;
  padding-bottom: .5em;
  border-bottom: solid rgba(189, 174, 174, 0.671) 1px;
  text-align: center;
  /* width: 400px; */
  width: 40%;
}

nav li {
  display: inline;
  margin: 1em;
  margin-left: 0;
  padding-left: 0;
  color: whitesmoke;
  cursor: pointer;
  font-weight: bold;
}

#viewport {
  background-image: url(/static/media/redpurpflower.75201c36.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100%;
  min-height: 95vh;
  width: 100%;
}

.section {
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  text-align:center;
  overflow:hidden;
  margin: 2em auto 0 auto;
  width: 700px;
}

.title {
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.05em;
}

.title, .info {
  text-align: left;
  display: block;
  margin: auto;
  width: 90%;
}

.video-container {
  margin-top: 1em;
}

#random-pic{
  height: 300px;
  width: 300px;
}
#random-pic:hover{
  cursor:pointer;
}

#music-player {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; 
  bottom: 0;
  background-color: whitesmoke;
  width: 100%;
  height: 55px;
  padding: 0 2em 0 2em;
}

.player-info {
  color:black;
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  padding-right: 20px;
  border-right: solid rgb(126, 126, 126) 1px;
}

.timestamp{
  color:black;
  font-size: 12px;
  width: 30px;
  text-align: center;
}

.song-title{
  color:black;
  font-size: 12px;
  font-weight: bold;
  margin-left: 1em;
}

#timeline{
  width: 400px;
  height: 10px;
  background: rgb(158, 157, 157);
  border-radius: 15px;
}
#timeline:hover{
  cursor:pointer;
}

#playhead{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 1px;
  background: black;
}

#pButton{
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
#pButton:focus{
  outline:none;
}
#pButton:hover{
  cursor:pointer;
}

@media (max-width: 1000px) {
  .player-info {
    display:none;
  }
}

@media (max-width: 750px) {
  #timeline {
    width: 200px;
  }
  body {
    font-size: .75em;
  }
  .section{
    width: 400px;
  }
}

@media (max-width: 450px) {
  #random-pic{
    height: 200px;
    width: 200px;
  }

  #homepage-note{
    display: none;
  }

  h1{
    font-size: 25px;
  }

  .section{
    width: 90%;
  }

  #music-player{
    justify-content: space-between;
  }

  #timeline, .timestamp{
    display: none;
  }

  .song-title{
    flex: 2 1;
  }
}

@media (max-width: 320px) {
  nav li {
    font-size:10px;
  }
}
